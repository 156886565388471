:root {
  --lms-l-orange: #f99b1c;
  --lms-m-orange: #f3702b;
  --lms-c-black: #4c5d6d;
  --lms-s-grey: #678099;
  --lms-d-orange: #ef432d;
  --lms-l-blue: #98b5d5;
  --lms-gold: #eec559;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/proximanova/proximanova-regular\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-italic';
  src: url('./assets/fonts/proximanova/proximanova-regularit.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold';
  src: url('./assets/fonts/proximanova/proximanova-bold\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold-italic';
  src: url('./assets/fonts/proximanova/proximanova-boldit\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-light';
  src: url('./assets/fonts//proximanova/proximanova-light\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-light-italic';
  src: url('./assets/fonts/proximanova/proximanova-lightit.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-medium';
  src: url('./assets/fonts/gotham/GothamHTF-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-bold';
  src: url('./assets/fonts/gotham/GothamHTF-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-book';
  src: url('./assets/fonts/gotham/GothamHTF-Book.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-xlight';
  src: url('./assets/fonts/gotham/GothamHTF-XLight.otf') format('opentype');
}


@font-face {
  font-family: 'archer-book';
  src: url('./assets/fonts/archer/Archer-Book.otf') format('opentype');
}

@font-face {
  font-family: 'archer-book-italic';
  src: url('./assets/fonts/archer/Archer-BookItal.otf') format('opentype');
}

@font-face {
  font-family: 'archer-semibold';
  src: url('./assets/fonts/archer/Archer-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'archer-semibold-italic';
  src: url('./assets/fonts/archer/Archer-SembdItal.otf') format('opentype');
}

@font-face {
  font-family: 'archer-bold';
  src: url('./assets/fonts/archer/Archer-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'archer-bold-italic';
  src: url('./assets/fonts/archer/Archer-BoldItal.otf') format('opentype');
}

.App {
  text-align: center;
}
